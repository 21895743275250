"use client";
import Link from "@/components/Link";
import { Button } from "@cgc/ui/button";
import { Heading, Paragraph } from "@cgc/ui/typography";
import Image from "next/image";
import React, { useState } from "react";
import { GiftBoxProps } from "./types";

const GiftBox: React.FC<GiftBoxProps> = ({
  title,
  discountLabel,
  image,
  price,
  fullPrice,
  shortDescription,
  description,
}) => {
  const [more, setMore] = useState(false);

  return (
    <div className="border-cgc-blue relative flex w-[319px] flex-col items-center justify-center gap-y-[15px] rounded-[20px] border-[5px] px-[20px] py-[30px] text-center">
      <Paragraph as="div" size="sub2" className="text-cgc-blue">
        {title}
      </Paragraph>
      <Paragraph
        as="div"
        size="sub2"
        className="bg-cgc-blue absolute -left-1 top-[20px] px-2 pb-1 pt-2 text-white"
      >
        {discountLabel}
      </Paragraph>
      {image && <Image src={image} width={90} height={90} alt={title} />}
      <div>
        <Heading display size="h4" as="div" className="text-cgc-blue mb-1">
          £{price}
          <span className="bg-cgc-yellow -mt-[17px] block h-2.5">&nbsp;</span>
        </Heading>
        <Heading
          size="h4"
          as="div"
          className="text-cgc-blue-40 mb-0 line-through"
        >
          £{fullPrice}
        </Heading>
      </div>
      <div>{shortDescription}</div>
      <div className="flex flex-col gap-1">
        <Link href="/">
          <Button className="px-4">GIFT {title}</Button>
        </Link>
        {!more && (
          <Button
            color="unstyled"
            onClick={() => setMore(true)}
            typoComponent={({ children }) => (
              <Paragraph
                as="span"
                size="p2"
                className="text-cgc-blue text-sm normal-case underline"
              >
                {children}
              </Paragraph>
            )}
          >
            Find out more
          </Button>
        )}
      </div>
      {more && <hr className="border-cgc-blue w-full border-t-2" />}
      {more && (
        <div>
          {description}
          <Button
            color="unstyled"
            onClick={() => setMore(false)}
            typoComponent={({ children }) => (
              <Paragraph
                as="span"
                size="p2"
                className="text-cgc-blue text-sm normal-case underline"
              >
                {children}
              </Paragraph>
            )}
          >
            Read less
          </Button>
        </div>
      )}
    </div>
  );
};

export default GiftBox;
