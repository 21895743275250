"use client";
import { BenefitsBlockButtonProps } from "@/components/BenefitsBlock/types";
import Link from "@/components/Link";
import { usePromoCode } from "@/data/helpers/promoCode/hooks";
import type { RetoolPromoCode } from "@/types/gql/graphql";
import { Button } from "@cgc/ui/button";
import { Paragraph } from "@cgc/ui/typography";
import React from "react";

type Props = BenefitsBlockButtonProps & {
  promoCode: RetoolPromoCode;
};
const PromoBlock: React.FC<Props> = ({
  promoCode,
  buttonLink,
  buttonCopy,
  bottomHelpText,
}) => {
  const { discountCampaign, text: codeText } = usePromoCode();
  const text = codeText ?? (discountCampaign ?? promoCode)?.heroLine;

  return (
    <>
      <div className="mb-2.5 px-2 text-center">
        {text ? (
          <Paragraph size="sub2">{text}</Paragraph>
        ) : (
          <div className="bg-grey-200 h-2 rounded-full" />
        )}
      </div>
      <Link href={buttonLink}>
        <Button color="secondary" className="mb-1.5" typoSize="sub3">
          {buttonCopy}
        </Button>
      </Link>
      {bottomHelpText && bottomHelpText}
    </>
  );
};

export default PromoBlock;
