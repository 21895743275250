"use client";

import { Label } from "@cgc/ui/Form";
import { Paragraph, ParagraphProps } from "@cgc/ui/typography";
import { clsx } from "@cgc/ui/utils/clsx";
import { useField } from "formik";
import React from "react";

const ResponsiveParagraph = (props: ParagraphProps) => (
  <>
    <Paragraph {...props} size="p3" mobileOnly />
    <Paragraph {...props} size="p2" desktopOnly />
  </>
);

type Props = {
  name: string;
  label?: string;
  labelClassName?: string;
  placeholder?: string;
  className?: string;
  onDark?: boolean;
  rows: number;
};

// FIXME: sync this field for full width newsletter subscription slice
// DEPRECATED
const TextAreaInputField: React.FC<Props> = ({
  name,
  label,
  labelClassName,
  placeholder,
  className,
  onDark,
  rows,
}) => {
  const [field, meta] = useField(name);
  const fieldId = `id_${field.name}`;
  const hasError = meta.touched && meta.error;
  const color = hasError ? "failure" : undefined;

  return (
    <div className="mb-2 w-full">
      {label && (
        <div className="mb-0.5 block">
          <Label htmlFor={fieldId} color={color} className={labelClassName}>
            {label}
          </Label>
        </div>
      )}
      <textarea
        rows={rows}
        className={clsx(
          "focus:shadow-0 border-cgc-white bg-cgc-white text-cgc-black w-full rounded text-base font-normal xl:text-lg",
          hasError && "!border-cgc-error",
          className,
        )}
        id={fieldId}
        placeholder={placeholder}
        {...field}
      />
      {hasError && (
        <ResponsiveParagraph
          className={clsx(
            "mt-1 whitespace-nowrap",
            onDark ? "text-cgc-error-light" : "text-cgc-error",
          )}
        >
          {meta.error}
        </ResponsiveParagraph>
      )}
    </div>
  );
};

export default TextAreaInputField;
