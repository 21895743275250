/**
 * Type for the form to be submitted
 */

export interface AccountCancellationForm {
  firstName: string;
  lastName: string;
  email: string;
  address: string;
  city: string;
  postcode: string;
  terminationDate: string;
}

export async function submitAccountCancellationForm(
  form: AccountCancellationForm,
): Promise<void> {
  const buildBody = () => {
    // Build a body with all the detail submitted by the user
    return `
      First Name: ${form.firstName}
      Last Name: ${form.lastName}
      Email: ${form.email}
      Address: ${form.address}
      City: ${form.city}
      Postcode: ${form.postcode}
      Termination Date: ${form.terminationDate}
    `;
  };

  const response = await fetch(
    "https://hook.eu1.make.com/yg9qi7amkhbwbsyv70hckxifmd4imfm1",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: form.email,
        subject: "Account cancellation request",
        body: buildBody(),
      }),
    },
  );

  if (!response.ok) {
    throw new Error("Failed to submit form");
  }

  return response.json();
}
