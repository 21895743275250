import { CommonComponent } from "@/components/types";
import { Paragraph } from "@cgc/ui/typography";

export const components = {
  paragraph: ({ children }: CommonComponent) => {
    return (
      <Paragraph size="p1" className="text-cgc-blue">
        {children}
      </Paragraph>
    );
  },
};
