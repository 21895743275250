/**
 * Type for the form to be submitted
 */

export interface ContactUsForm {
  email: string;
  subject: string;
  comments: string;
}

export async function submitContactUsForm(form: ContactUsForm): Promise<void> {
  const response = await fetch(
    "https://hook.eu1.make.com/yg9qi7amkhbwbsyv70hckxifmd4imfm1",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: form.email,
        subject: form.subject,
        body: form.comments,
      }),
    },
  );

  if (!response.ok) {
    throw new Error("Failed to submit form");
  }

  return response.json();
}
