import type { JSX, PropsWithChildren } from "react";
import { clsx } from "../../utils/clsx";
import { StarHalf, Star } from "./stars";

interface StarProps {
  half?: boolean;
  white?: boolean;
}

type RatingProps = PropsWithChildren & {
  className?: string;
};

export function Rating({ children, className }: RatingProps): JSX.Element {
  return <div className={clsx("flex items-center gap-0.5", className)}>{children}</div>;
}

function RatingStar({ half = false, white = false }: StarProps): JSX.Element {
  return half ? <StarHalf /> : <Star color={white ? "white" : undefined} />;
}
Rating.Star = RatingStar;
