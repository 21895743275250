"use client";
import { Container } from "@cgc/ui/layout";
import { useSearchParams } from "next/navigation";
import Script from "next/script";
import { useEffect, useState } from "react";

declare global {
  interface Window {
    sovIframes: any;
  }
}

type SovendusProps = {
  trafficSourceNumber?: number;
  trafficMediumNumber?: number;
  iframeContainerId?: string;
};

const SovendusSnippet = ({
  trafficSourceNumber = 6282,
  trafficMediumNumber = 4,
  iframeContainerId = "sovendus-container-1",
}: SovendusProps) => {
  const searchParams = useSearchParams();
  const timestamp = searchParams?.get("cart_created_at") ?? "";
  const orderId = searchParams?.get("order_id") ?? "";
  const totalPrice = searchParams?.get("total_price") ?? "";
  const discountCode = searchParams?.get("discount_code") ?? "";
  const firstName = searchParams?.get("fn") ?? "";
  const lastName = searchParams?.get("ln") ?? "";

  const [ready, setReady] = useState(false);

  useEffect(() => {
    window.sovIframes = window.sovIframes || [];
    window.sovIframes.push({
      trafficSourceNumber,
      trafficMediumNumber,
      sessionId: orderId,
      timestamp,
      orderId: orderId,
      orderValue: totalPrice,
      orderCurrency: "GBP",
      usedCouponCode: discountCode,
      iframeContainerId,
    });
    window.sovConsumer = window.sovConsumer || {};
    window.sovConsumer = {
      consumerSalutation: 'Salutation ("Mr." or "Mrs")',
      consumerFirstName: firstName,
      consumerLastName: lastName,
      consumerEmail: "email",
      consumerStreet: "client address (street)",
      consumerStreetNumber: "client address (house number)",
      consumerCountry: "client address (Country)",
      consumerZipcode: "client address (zip code)",
    };
    setReady(true);
  }, []);

  return (
    <Container>
      <div id={iframeContainerId} />
      {ready && (
        <Script src="https://api.sovendus.com/sovabo/common/js/flexibleIframe.js" />
      )}
    </Container>
  );
};

export default SovendusSnippet;
