"use client";
import { useQuery } from "@apollo/client/react/hooks";
import Image from "next/image";
import React, { useRef } from "react";

import Skeleton from "@/components/MemberReviews/Skeleton";
import { ReviewIOReviewsQuery } from "@/data/helpers/reviewIO/queries";
import type { ReviewIoItem } from "@/types/gql/graphql";
import { Button } from "@cgc/ui/button";
import { Section } from "@cgc/ui/layout";
import { Rating } from "@cgc/ui/rating";
import { Heading, Paragraph } from "@cgc/ui/typography";

import arrow from "./arrow.svg";
import reviewsLogo from "./reviewsio-logo.svg";

const LIMIT = 100;

type ReviewProps = {
  name: string;
  comment: string;
  rating: number;
  date: string;
};

const Review = ({ name, comment, rating, date }: ReviewProps) => (
  <div className="bg-cgc-blue flex w-[265px] shrink-0 snap-center snap-always flex-col items-center rounded-[10px] px-[15px] py-[30px] text-white">
    <Paragraph size={"sub2"} className="w-full overflow-hidden uppercase">
      {name}
    </Paragraph>
    <Rating className="mb-3">
      {Array.from({ length: rating }).map((_, index) => (
        <Rating.Star white key={`${name}_${index}`} />
      ))}
    </Rating>
    <Paragraph>
      {comment.length > LIMIT ? `${comment.slice(0, LIMIT)}...` : comment}
    </Paragraph>
    <Paragraph size="p3" className="mt-auto">
      Posted {date}
    </Paragraph>
  </div>
);

const MemberReviews: React.FC<{ id: string; title?: string }> = ({
  id,
  title,
}) => {
  const container = useRef<HTMLDivElement | null>(null);
  const { data } = useQuery(ReviewIOReviewsQuery);
  const { reviews, stats, word } = data?.getReviewIOReviews ?? {};

  return (
    <Section id={id} className="mb-0 text-center">
      <Heading.h2 className="mb-[20px]">
        {title ?? "WHAT OUR MEMBERS SAY"}
      </Heading.h2>
      {!data && <Skeleton />}
      {data && (
        <>
          <div className="mb-[30px] flex flex-row flex-wrap items-start justify-center gap-[20px]">
            <Paragraph as="div" size="sub2" className="uppercase">
              {word}
            </Paragraph>
            <Rating>
              <Rating.Star />
              <Rating.Star />
              <Rating.Star />
              <Rating.Star />
              <Rating.Star half />
            </Rating>
            <Paragraph as="div">{stats.average_rating} average</Paragraph>
            <Paragraph as="div">{stats.total_reviews} reviews</Paragraph>
            <Image src={reviewsLogo} alt={"Reviews.io Logo"} />
          </div>
          <div
            ref={container}
            className="no-scrollbar relative mb-5 flex w-full snap-x snap-mandatory gap-5 overflow-x-auto scroll-smooth"
          >
            {reviews.slice(0, 15).map((review: ReviewIoItem) => (
              <Review
                key={review.store_review_id}
                name={review.reviewer?.first_name ?? "Anonymous"}
                rating={review.rating}
                comment={review.comments}
                date={review.date}
              />
            ))}
          </div>
        </>
      )}
      <Button
        color="unstyled"
        onClick={() => {
          if (container.current) {
            container.current.scrollLeft += 300;
          }
        }}
        typoComponent={() => (
          <Paragraph
            size="sub3"
            as="span"
            className="flex flex-row items-center"
          >
            SEE MORE REVIEWS <Image src={arrow} alt="" className="-mt-1" />
          </Paragraph>
        )}
      />
    </Section>
  );
};

export default MemberReviews;
