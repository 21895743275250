"use client";
import { gql } from "@apollo/client/core";
import { useMutation } from "@apollo/client/react/hooks";
import { Button } from "@cgc/ui/button";
import { Spinner } from "@cgc/ui/spinner";
import { Paragraph } from "@cgc/ui/typography";
import shuffle from "lodash/shuffle";
import { useSearchParams } from "next/navigation";
import React, { useCallback, useEffect } from "react";

export const SaveSurveyResultMutation = gql`
  mutation SaveSurveyResult($input: SurveyResultInput!) {
    saveSurveyResult(input: $input)
  }
`;

const QUESTIONS = [
  "Saw your advert in a newspaper/ mag",
  "Received your flyer",
  "Spotted you on social media",
  "Watched your advert on TV",
  "Caught your advert on a podcast",
  "Heard you on the radio",
  "Searched you online",
  "Recommended by a friend",
];

type SurveyProps = {
  productType?: string;
  onSuccess?: () => void;
};

const Survey: React.FC<SurveyProps> = ({
  productType,
  onSuccess = () => null,
}) => {
  const searchParams = useSearchParams();
  const orderId = searchParams?.get("order_id");

  const [saveSurveyResult, { data, loading }] = useMutation(
    SaveSurveyResultMutation,
  );
  const isSuccess = !!data?.saveSurveyResult;

  const submit = useCallback(
    (answer: string) => {
      saveSurveyResult({
        variables: {
          input: {
            answer,
            orderId,
            productType,
          },
        },
      });
    },
    [saveSurveyResult, orderId],
  );

  useEffect(() => {
    if (isSuccess) {
      onSuccess();
    }
  }, [isSuccess, onSuccess]);

  return (
    <div className="space-y-3 text-center">
      {isSuccess ? (
        <>
          <Paragraph size="sub2">Thank you!</Paragraph>
          <Paragraph>Response submitted</Paragraph>
        </>
      ) : (
        <>
          <Paragraph size="sub2" className="text-cgc-blue mb-2">
            Welcome to the club!
          </Paragraph>
          <Paragraph className="!text-sm">
            We’d LOVE to know how you heard about us.{" "}
            <strong>Please select one.</strong>
          </Paragraph>
          {loading && <Spinner className="h-10 w-10" />}
          {!loading && (
            <div className="space-y-3">
              {shuffle(QUESTIONS).map((answer) => (
                <Button
                  key={answer}
                  color="unstyled"
                  className="bg-cgc-green-20 w-full rounded px-4 py-1"
                  typoComponent={({ children }) => (
                    <Paragraph as="span" className="!text-sm normal-case">
                      {children}
                    </Paragraph>
                  )}
                  onClick={() => submit(answer)}
                >
                  {answer as string}
                </Button>
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Survey;
