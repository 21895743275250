"use client";
import Link from "@/components/Link";
import { BLOG_INDEX_URL } from "@/data/helpers/blog/urls";
import { addToSearchParams } from "@/utils/search";
import { clsx } from "@cgc/ui/utils/clsx";
import { useSearchParams } from "next/navigation";
import React, { PropsWithChildren } from "react";

type Props = {
  param: string;
  value: string;
  className?: string;
  onClick?: (tag: string) => void;
};
const FilterLink: React.FC<PropsWithChildren<Props>> = ({
  param,
  value,
  className,
  children,
  onClick,
}) => {
  const searchParams = useSearchParams()?.toString();

  return (
    <Link
      href={`${BLOG_INDEX_URL}?${addToSearchParams(searchParams, {
        [param]: value,
        page: "", // reset pagination
      })}`}
      className={clsx("no-underline", className)}
      {...{
        ...(onClick
          ? {
              onClick: (event) => {
                event.preventDefault();
                onClick(value);
              },
            }
          : null),
      }}
    >
      {children}
    </Link>
  );
};

export default FilterLink;
