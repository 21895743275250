"use client";

import Link from "@/components/Link";
import { usePromoCode } from "@/data/helpers/promoCode/hooks";
import { Logo } from "@cgc/ui/logo";
import { clsx } from "@cgc/ui/utils/clsx";
import { benefitsRenderAllowed } from "./utils";

export function HeroLogo({ className }: { className?: string }) {
  return (
    <Link
      href="/"
      className={clsx("-mt-[130px] md:mt-0", className)}
      aria-label={"Navigate to Craft Gin Club home page"}
    >
      <Logo className="mb-5 h-[80px] w-[80px] drop-shadow-lg" circled />
    </Link>
  );
}
export default function DynamicLogo() {
  const { code } = usePromoCode();
  if (benefitsRenderAllowed(code)) {
    return <HeroLogo className="hidden lg:block" />;
  }

  return <HeroLogo />;
}
