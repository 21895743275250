import type { JSX } from "react";
import { clsx } from "../../utils/clsx";

interface StarProps {
  className?: string;
  color?: "white";
}

export function Star({ className, color }: StarProps): JSX.Element {
  return (
    <svg
      height={20}
      id="Layer_2"
      viewBox="0 0 95.1 90.59"
      width={20}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="CONTENT">
        <polygon
          className={clsx(
            "fill-cgc-yellow stroke-2 stroke-cgc-blue",
            color === "white" && "fill-cgc-white",
            className,
          )}
          points="62.22 29.7 95.1 34.32 71.44 57.61 77.21 90.31 47.74 75 18.43 90.59 23.89 57.84 0 34.77 32.84 29.84 47.39 0 62.22 29.7"
        />
      </g>
    </svg>
  );
}

// export const StarHalfGradient = ({ className }: Props) => (
//   <svg id="Layer_2" viewBox="0 0 95.1 90.59" width={20} height={20}>
//     <defs>
//       <linearGradient
//         id="linear-gradient"
//         x1="0"
//         y1="45.29"
//         x2="95.1"
//         y2="45.29"
//         gradientUnits="userSpaceOnUse"
//       >
//         <stop offset="0" stopColor="#f4da40" />
//         <stop offset=".2" stopColor="#f2d840" />
//         <stop offset=".27" stopColor="#ebd242" />
//         <stop offset=".33" stopColor="#dfc846" />
//         <stop offset=".37" stopColor="#ceb94b" />
//         <stop offset=".4" stopColor="#b8a651" />
//         <stop offset=".43" stopColor="#9d8f59" />
//         <stop offset=".46" stopColor="#7d7363" />
//         <stop offset=".49" stopColor="#58546e" />
//         <stop offset=".51" stopColor="#343579" />
//       </linearGradient>
//     </defs>
//     <g id="CONTENT">
//       <polygon
//         className={clsx("stroke-0", className)}
//         style={{ fill: "url(#linear-gradient)" }}
//         points="62.22 29.7 95.1 34.32 71.44 57.61 77.21 90.31 47.74 75 18.43 90.59 23.89 57.84 0 34.77 32.84 29.84 47.39 0 62.22 29.7"
//       />
//     </g>
//   </svg>
// );

export function StarHalf(): JSX.Element {
  return (
    <svg
      id="Layer_2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 95.1 90.59"
      width={20}
      height={20}
    >
      <g id="CONTENT">
        <polyline
          className="fill-cgc-yellow stroke-2 stroke-cgc-blue"
          points="47.74 75 18.43 90.59 23.89 57.84 0 34.77 32.84 29.84 47.39 0"
        />
        <polyline
          className="fill-cgc-blue"
          points="47.39 0 62.22 29.7 95.1 34.32 71.44 57.61 77.21 90.31 47.74 75"
        />
      </g>
    </svg>
  );
}
