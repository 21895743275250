"use client";
import Carousel from "nuka-carousel";
import React, { useCallback, type JSX, type PropsWithChildren } from "react";
import Sticky from "react-stickynode";

import {
  DeliveryCarIcon,
  FourBottlesIcon,
  GinBottlesIcon,
  GinBoxIcon,
} from "@cgc/ui/icons";

import { Paragraph, type ParagraphProps } from "@cgc/ui/typography";

import { usePromoCode } from "@/data/helpers/promoCode/hooks";
import BenefitsPromoLine from "./Line";

function Benefit({ children }: PropsWithChildren): JSX.Element {
  return (
    <div className="flex w-full flex-row items-center justify-center gap-x-2 lg:w-auto">
      {children}
    </div>
  );
}

function BenefitParagraph(props: ParagraphProps): JSX.Element {
  return (
    <Paragraph
      {...props}
      className="text-cgc-blue !text-[12px] font-semibold sm:text-base"
    />
  );
}

const CONTENT_MAP: Record<
  string,
  { label: string; icon: React.ElementType }[]
> = {
  default: [
    { label: "Total flexibility, no commitment", icon: GinBoxIcon },
    { label: "A world of unique, crafted spirits", icon: GinBottlesIcon },
    { label: "Easy, free and reliable delivery", icon: DeliveryCarIcon },
  ],
  gotm_taster: [
    { label: "Free gin taster box", icon: FourBottlesIcon },
    { label: "Total flexibility, no commitment", icon: GinBoxIcon },
    { label: "A world of unique, crafted spirits", icon: GinBottlesIcon },
  ],
};

const isGotmTaster = (code: string) =>
  code.startsWith("SIP-") || code.startsWith("MM-SIP-");

const BenefitsBanner: React.FC = () => {
  const { code } = usePromoCode();
  const benefits = !code
    ? CONTENT_MAP.default
    : isGotmTaster(code)
      ? CONTENT_MAP.gotm_taster
      : CONTENT_MAP.default;

  const renderedBenefits = useCallback(
    (): JSX.Element[] =>
      benefits.map(({ label, icon: Icon }) => (
        <Benefit key={label}>
          <Icon className="h-[25px] lg:h-[25px]" />{" "}
          <BenefitParagraph>{label}</BenefitParagraph>
        </Benefit>
      )),
    [code],
  );

  return (
    <Sticky enabled innerZ={49}>
      <BenefitsPromoLine>
        <Carousel
          carouselId="benefits"
          autoplay
          wrapAround
          autoplayInterval={3000}
          withoutControls
          className="lg:hidden"
        >
          {renderedBenefits()}
        </Carousel>
        <div className="hidden flex-row items-center justify-center lg:flex lg:gap-x-9">
          {renderedBenefits()}
        </div>
      </BenefitsPromoLine>
    </Sticky>
  );
};

export default BenefitsBanner;
