"use client";

import BenefitBar from "@/components/BenefitBar";
import { Paragraph } from "@cgc/ui/typography";
import { Content } from "@prismicio/client";
import { PrismicNextImage } from "@prismicio/next";
import { SliceComponentProps } from "@prismicio/react";

export type NavigationBarProps =
  SliceComponentProps<Content.NavigationBarSlice>;

const NavigationBar = ({ slice }: NavigationBarProps): JSX.Element => {
  return (
    <section
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
      className=""
    >
      <div className="mx-auto flex flex-col">
        <div className="relative order-last mx-auto flex w-full max-w-screen-xl items-center justify-between px-4 py-4 md:px-6 lg:order-first lg:px-20">
          <div className="flex items-center">
            <PrismicNextImage
              field={slice.primary.logo_image}
              className="h-auto w-24 object-cover lg:w-24 xl:absolute xl:left-0 xl:top-0 xl:w-44"
            />
          </div>

          <div className="ml-4 flex w-52 flex-col items-center gap-4 space-x-2 lg:block">
            <PrismicNextImage
              field={slice.primary.step_image}
              className="h-12 w-full object-contain"
            />
            <div className="w-[200px] items-center text-sm lg:hidden">
              <PrismicNextImage
                field={slice.primary.reviewsio_image}
                className="h-4 w-full object-cover"
              />
            </div>
          </div>

          <div className="hidden w-[200px] items-center text-sm lg:block">
            <PrismicNextImage
              field={slice.primary.reviewsio_image}
              className="h-4 w-full object-cover"
            />
          </div>
        </div>
        <div className="bg-cgc-green-20 order-first flex justify-between py-2 lg:order-last lg:mt-4">
          <div className="w-full lg:hidden">
            <BenefitBar>
              {slice.primary.benefits.map((item) => {
                return (
                  <div className="flex min-w-[100vw] items-center justify-center gap-2 transition-opacity duration-300 md:min-w-fit md:px-4">
                    <PrismicNextImage
                      field={item.image}
                      className="h-auto w-6 object-cover"
                    />
                    <Paragraph
                      size="p2"
                      className="text-cgc-blue text-sm font-semibold"
                    >
                      {item.description}
                    </Paragraph>
                  </div>
                );
              })}
            </BenefitBar>
          </div>
          <div className="mx-auto hidden w-full max-w-screen-lg flex-row justify-between lg:flex xl:pl-12">
            {slice.primary.benefits.map((item) => {
              return (
                <div className="flex min-w-[100vw] items-center justify-center gap-2 transition-opacity duration-300 md:min-w-fit md:px-4">
                  <PrismicNextImage
                    field={item.image}
                    className="h-auto w-6 object-cover"
                  />
                  <Paragraph size="p2" className="text-cgc-blue font-semibold">
                    {item.description}
                  </Paragraph>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default NavigationBar;
