export const fallbackLanguage = "en-us";
export const languages = [fallbackLanguage, "de-de", "nl-nl"];
export const defaultNS = "translation";
export const cookieName = "i18next";

export function getOptions(
  lng = fallbackLanguage,
  ns: string | string[] = defaultNS,
) {
  return {
    supportedLanguages: languages,
    fallbackLanguage,
    lng,
    fallbackNS: defaultNS,
    defaultNS,
    ns,
  };
}
