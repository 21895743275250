"use client";

import { ReviewIOReviewsQuery } from "@/data/helpers/reviewIO/queries";
import { useQuery } from "@apollo/client/react/hooks";
import { Rating } from "@cgc/ui/rating";

function Skeleton() {
  return (
    <div className="flex gap-2">
      <p>
        <span className="inline-block w-[60px] animate-pulse rounded-full bg-gray-300">
          &nbsp;
        </span>{" "}
        customer reviews
      </p>
      <Rating>
        <Rating.Star />
        <Rating.Star />
        <Rating.Star />
        <Rating.Star />
        <Rating.Star half />
      </Rating>
    </div>
  );
}

function Reviews(stats: any) {
  return (
    <div className="flex gap-2">
      <p>{stats.total_reviews} customer reviews</p>
      <Rating>
        <Rating.Star />
        <Rating.Star />
        <Rating.Star />
        <Rating.Star />
        <Rating.Star half />
      </Rating>
    </div>
  );
}

export function InlineReviews() {
  const { data } = useQuery(ReviewIOReviewsQuery);
  const { stats } = data?.getReviewIOReviews ?? {};

  return (
    <div>
      <a
        className="cursor-pointer text-inherit no-underline"
        href="https://www.reviews.co.uk/company-reviews/store/craft-clubs"
      >
        {stats ? <Reviews stats={stats} /> : <Skeleton />}
      </a>
    </div>
  );
}
