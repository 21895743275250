"use client";
import Cookies from "js-cookie";
import React from "react";

import { Button, ButtonProps } from "@cgc/ui/button";
import { useModal } from "@cgc/ui/modal";

import Survey from "@/components/Survey/Survey";

const cookieKey = "signup_survey_done";

type SurveyButtonProps = ButtonProps & {
  allowOnce?: boolean;
  productType?: string;
};

const SurveyButton: React.FC<SurveyButtonProps> = ({
  allowOnce = true,
  productType,
  ...props
}) => {
  const { Modal, setIsOpen } = useModal();

  const open = () => {
    if (allowOnce && Cookies.get(cookieKey)) {
      return;
    }
    setIsOpen(true);
  };

  const onSuccess = () => {
    if (allowOnce) {
      Cookies.set(cookieKey, "true", { expires: 365 * 100 });
    }
  };

  return (
    <>
      <Button {...props} color="secondary" onClick={open} />
      <Modal>
        <Survey onSuccess={onSuccess} productType={productType} />
      </Modal>
    </>
  );
};

export default SurveyButton;
