"use client";

import {
  ContactUsForm,
  submitContactUsForm,
} from "@/app/data/account/contact-us.action";
import { useTranslation } from "@/app/i18n/client";
import TextAreaInputField from "@/components/ui/Form/TextAreaInputField";
import TextInputField from "@/components/ui/Form/TextInputField";
import { CGCPrismicContext } from "@/prismicio";
import { styleGuideTypography } from "@/utils/style-guide-typography";
import { Button } from "@cgc/ui/button";
import { Content } from "@prismicio/client";
import { PrismicRichText, SliceComponentProps } from "@prismicio/react";
import { Form, Formik, FormikHelpers } from "formik";
import * as Yup from "yup";

/**
 * Props for `CancellationForm`.
 */
export type CancellationFormProps = SliceComponentProps<
  Content.CancellationFormSlice,
  CGCPrismicContext
>;

/**
 * Component for "CancellationForm" Slices.
 */
const CancellationForm = ({
  slice,
  context,
}: CancellationFormProps): JSX.Element => {
  const { t } = useTranslation(context.lang, "slices");

  const validationSchema = Yup.object<ContactUsForm>({
    email: Yup.string()
      .email(t("contact-us.fields.email.validation.invalid"))
      .trim()
      .required(t("contact-us.fields.email.validation.required")),
    subject: Yup.string()
      .trim()
      .required(t("contact-us.fields.subject.validation.required")),
    comments: Yup.string()
      .trim()
      .required(t("contact-us.fields.comments.validation.required")),
  });

  const handleFormSubmit = async (
    values: ContactUsForm,
    formikHelpers: FormikHelpers<ContactUsForm>,
  ): Promise<any> => {
    await submitContactUsForm(values);

    formikHelpers.setSubmitting(false);
  };

  return (
    <section
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
      className="text-cgc-blue w-full"
    >
      <div className="relative mx-auto flex w-full max-w-screen-xl flex-col items-center gap-6 px-8 py-6 lg:px-16 lg:py-12">
        {/* Copy */}
        <div className="text-balance text-center">
          <PrismicRichText
            field={slice.primary.header}
            components={styleGuideTypography}
          />
        </div>

        <Formik<ContactUsForm>
          initialValues={{
            email: "",
            subject: "",
            comments: "",
          }}
          validationSchema={validationSchema}
          onSubmit={handleFormSubmit}
        >
          {({ isSubmitting }) => (
            <Form className="w-full space-y-1">
              <TextInputField
                name="email"
                placeholder={t("contact-us.fields.email.placeholder")}
                label={t("contact-us.fields.email.label")}
              />

              <TextInputField
                name="subject"
                placeholder={t("contact-us.fields.subject.placeholder")}
                label={t("contact-us.fields.subject.label")}
              />

              <TextAreaInputField
                className="border-cgc-blue border"
                label={t("contact-us.fields.comments.label")}
                name="comments"
                rows={4}
              />

              <Button
                color="primary"
                className="!mt-4 w-full max-w-96"
                type="submit"
                isLoading={isSubmitting}
                disabled={isSubmitting}
              >
                {t("contact-us.fields.submit.label")}
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </section>
  );
};

export default CancellationForm;
