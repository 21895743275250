import React, { useEffect, useRef, useState } from "react";

const BenefitBar = ({ children }) => {
  const [translateX, setTranslateX] = useState(0);
  const containerRef = useRef(null);
  const childrenCount = React.Children.count(children);

  useEffect(() => {
    const interval = setInterval(() => {
      setTranslateX((prevTranslate) => {
        if (prevTranslate <= -((childrenCount - 1) * 100)) {
          setTimeout(() => {
            if (containerRef.current) {
              containerRef.current.style.transition = "none";
              setTranslateX(0);
              setTimeout(() => {
                if (containerRef.current) {
                  containerRef.current.style.transition =
                    "transform 1000ms ease-in-out";
                }
              }, 50);
            }
          }, 1000);

          return prevTranslate - 100;
        }

        return prevTranslate - 100;
      });
    }, 4000);

    return () => clearInterval(interval);
  }, [childrenCount]);

  return (
    <div className="overflow-hidden">
      <div
        ref={containerRef}
        className="flex transition-transform duration-1000 ease-in-out"
        style={{ transform: `translateX(${translateX}%)` }}
      >
        {children}
        {React.Children.map(children, (child, index) =>
          React.cloneElement(child, { key: `clone-${index}` }),
        )}
      </div>
    </div>
  );
};

export default BenefitBar;
